import { render, staticRenderFns } from "./main-nav.vue?vue&type=template&id=5bd1a5c9&scoped=true"
import script from "./main-nav.vue?vue&type=script&lang=ts"
export * from "./main-nav.vue?vue&type=script&lang=ts"
import style0 from "./main-nav.vue?vue&type=style&index=0&id=5bd1a5c9&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bd1a5c9",
  null
  
)

export default component.exports