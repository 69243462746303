var _a, _b;
import { __decorate, __metadata } from "tslib";
import ReceiptSummary from '@/pages/dashboards/pages/ds/pages/receipt-summary';
import EngagementReport from '@/pages/engagement-report/engagement-report.vue';
import MainNav from './_components/main-nav';
import GlobalSearch from './_components/global-search.vue';
import Box from '@/shared/layout/box/layout-box.vue';
import Avatar from '@/shared/components/avatar/avatar.vue';
import Popover from '@/shared/components/popover/popover.vue';
import Icon from '@/shared/components/icon/icon--solid.vue';
import { MatchFirst, Redirect } from 'vue-component-router';
import ScrollablePanel from '@/shared/components/scrollable-panel/scrollable-panel.vue';
import { User } from '@/shared/authentication';
import Vue from 'vue';
import Loading from '@/shared/components/loading/Loading.vue';
import { RouterLink } from 'vue-component-router';
import { Prop, Component } from 'vue-property-decorator';
import UserSelector from './_components/user-selector';
import Dashboards from '@/pages/dashboards/dashboards.vue';
import ProtectedBlock from '@/shared/authentication/ProtectedBlock';
import { UserRole, UserType, Department } from '@/shared/schema/globalTypes';
import NotificationsContainer from '@/shared/notifications';
import { ErrorBoundary } from 'vue-error-boundary';
import { RouteWithErrorBoundary as Route } from '@/shared/route-with-error-boundary';
import { resolveDepartments } from '@/shared/util';
import { intersection } from 'lodash';
let AppLayout = class AppLayout extends Vue {
    currentUser;
    originalUser;
    emulateUser;
    hasRole;
    canGenerateInvoicesAndReceipts;
    loading;
    showPledge = false;
    menuOpen = true;
    showEmulateDialog = false;
    UserRole = UserRole;
    handleError(err) {
        console.error(err);
    }
    get emulateableUsers() {
        return (this.originalUser.emulateableUsers || []).filter(user => user.active);
    }
    get canEmulate() {
        return this.emulateableUsers.length > 0;
    }
    get canAccessDataservices() {
        return this.currentUser.type !== UserType.Fundraiser;
    }
    get isCEO() {
        return this.currentUser.type === UserType.CEO;
    }
    get isAccounting() {
        return this.currentUser.type === UserType.Accounting;
    }
    get belongsToTeachCoalition() {
        const userDepts = this.currentUser.departments ? resolveDepartments(this.currentUser.departments) : [];
        return intersection(userDepts, resolveDepartments(Department.Teach_AdminGroup)).length ? true : false;
    }
    toggleMenu() {
        this.menuOpen = !this.menuOpen;
    }
    togglePledge() {
        this.showPledge = !this.showPledge;
    }
    get userSelectorProps() {
        return {
            users: this.emulateableUsers
                .sort((a, b) => (a.lastName || '').localeCompare(b.lastName || ''))
                .map(x => ({
                name: `${x.firstName} ${x.lastName}`,
                id: x.id
            }))
        };
    }
    get mainPageRedirectUrl() {
        let endpoint = 'summary';
        if (this.currentUser.type === UserType.DataServices) {
            endpoint = 'receipts-summary';
        }
        else if (this.currentUser.type === UserType.Accounting) {
            endpoint = 'reports';
        }
        return `/dashboards/${this.currentUser.id}/${endpoint}`;
    }
};
__decorate([
    Prop(),
    __metadata("design:type", typeof (_a = typeof User !== "undefined" && User) === "function" ? _a : Object)
], AppLayout.prototype, "currentUser", void 0);
__decorate([
    Prop(),
    __metadata("design:type", typeof (_b = typeof User !== "undefined" && User) === "function" ? _b : Object)
], AppLayout.prototype, "originalUser", void 0);
__decorate([
    Prop({ type: Function }),
    __metadata("design:type", Object)
], AppLayout.prototype, "emulateUser", void 0);
__decorate([
    Prop({ type: Function }),
    __metadata("design:type", Object)
], AppLayout.prototype, "hasRole", void 0);
__decorate([
    Prop({ type: Function }),
    __metadata("design:type", Object)
], AppLayout.prototype, "canGenerateInvoicesAndReceipts", void 0);
__decorate([
    Prop(),
    __metadata("design:type", Boolean)
], AppLayout.prototype, "loading", void 0);
AppLayout = __decorate([
    Component({
        components: {
            ReceiptSummary,
            EngagementReport,
            Route,
            ErrorBoundary,
            Redirect,
            ProtectedBlock,
            NotificationsContainer,
            Dashboards,
            MainNav,
            Popover,
            GlobalSearch,
            Avatar,
            ScrollablePanel,
            Icon,
            Box,
            MatchFirst,
            OpenPledges: () => import('./open-pledges'),
            Retention: () => import('./retention-reports/retention.vue'),
            FundraisingLibrary: () => import('./dashboards/pages/fundraising-library/fundraising-library.vue'),
            OUANetworks: () => import('./oua-networks'),
            OUARegions: () => import('./oua-regions'),
            Campaigns: () => import('./campaignmanagement/listing'),
            CampaignsGlCodes: () => import('./campaignmanagement/glcodes'),
            Campaign: () => import('./campaignmanagement/campaign'),
            Households: () => import('./household'),
            DashboardNCSYDashboard: () => import('./ncsy-dashboard/ncsy-dashboard.vue'),
            DashoboardTeachDashboard: () => import('./teach-dashboard/teach-dashboard.vue'),
            NewDonor: () => import('./new-donor'),
            NewPledge: () => import('./new-pledge/new-pledge.vue'),
            NewPayment: () => import('./new-payment/new-payment.vue'),
            NewGrant: () => import('./new-grant/new-grant.vue'),
            NetworkForm: () => import('./oua-networks/pages/form/network-form.vue'),
            SingleNetwork: () => import('./oua-networks/pages/single/single.vue'),
            NewCampaign: () => import('./new-campaign'),
            Benefactor: () => import('./benefactors'),
            PlannedGiving: () => import('./planned-giving'),
            BenefactorByQuarter: () => import('./benefactors-by-quarter'),
            BenefactorMembers: () => import('./benefactors-members'),
            Employees: () => import('./ouemployees'),
            Boardmembers: () => import('./boardmembers/listing'),
            BoardList: () => import('./boardmembers/board-listing'),
            BoardmembersPayments: () => import('./boardmembers/payments'),
            CommissionMembers: () => import('./commission-members'),
            CommissionMembersPayments: () => import('./commission-members/payments'),
            CommitteeMembers: () => import('./committee-members'),
            CommitteeMembersPayments: () => import('./committee-members/payments'),
            Moves: () => import('./moves'),
            Batches: () => import('./batches/listing'),
            PledgeManagment: () => import('./pledge-managment'),
            PledgeToday: () => import('./pledge-today/listing.vue'),
            Batch: () => import('./batches/single'),
            Reconciliation: () => import('./reconciliation'),
            ReconciliationCardknox: () => import('./reconciliation-cardknox'),
            BulkPledgeChanges: () => import('./bulk-pledge-changes'),
            BulkPaymentChanges: () => import('./bulk-payment-changes'),
            NewBatch: () => import('./batches/new'),
            Departments: () => import('./departments'),
            Department: () => import('./departments/single'),
            WebImports: () => import('./webimports'),
            Foundations: () => import('./foundations'),
            FoundationsReport: () => import('./foundations/report'),
            Dups: () => import('./duplicates'),
            Users: () => import('./users/listing'),
            User: () => import('./users/user'),
            NewUser: () => import('./users/new-user'),
            Search: () => import('./advanced-search'),
            DonorsWOAddress: () => import('./donors-report/without-address.vue'),
            DonorsWOSalutation: () => import('./donors-report/without-salutation.vue'),
            AdmireMapping: () => import('./dataservices/pages/admire-mapping'),
            NewHouseholds: () => import('./dataservices/pages/newhouseholds'),
            Pending: () => import('./dataservices/pages/pending/index.vue'),
            Checks: () => import('./dataservices/pages/checks'),
            UploadChecks: () => import('./dataservices/pages/checks/upload-checks'),
            XlsImport: () => import('./dataservices/pages/xls-import'),
            CdsImport: () => import('./dataservices/pages/cds-import'),
            SolicitationsImport: () => import('./dataservices/pages/solicitations-import'),
            DonorsByEmails: () => import('./dataservices/pages/match-by-email'),
            UnclearedDafPledgesPage: () => import('./UnclearedDafPledgesPage.vue'),
            Donors: () => import('./donors'),
            GoalPipeline: () => import('./goal-pipeline'),
            GoalsReport: () => import('./goals-report'),
            Reports: () => import('./reports'),
            DynamicReports: () => import('./dynamic-reports'),
            QuickLookup: () => import('./quick-lookup/quicklookup.vue'),
            GenerateInvoice: () => import('./generate-invoices'),
            GenerateMembershipCards: () => import('./generate-membership-cards'),
            Payments: () => import('./payments'),
            Resources: () => import('./resources/resources.vue'),
            Grants: () => import('./grants'),
            GrantIdeas: () => import('./grant-ideas'),
            DonorListsPage: () => import('./donor-lists-page'),
            FundraiserActions: () => import('./fundraiser-actions'),
            LeadershipReports: () => import('./leadership-reports/index.vue'),
            OutgoingSMS: () => import('./sms'),
            RecurringPayments: () => import('./recurring-payments'),
            DebtReport: () => import('./bad-debt-report'),
            AccountingBudget: () => import('./accounting-budget'),
            OuFundrasing: () => import('./ou-fundrasing-report'),
            OuMembership: () => import('./ou-membership'),
            Telemarketing: () => import('./telemarketing'),
            NotificationSettings: () => import('./notification-settings'),
            Loading,
            RouterLink,
            UserSelector
        }
    })
], AppLayout);
export default AppLayout;
